import React, { useEffect, useState } from "react";


import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";
import Cart from "../cart/Cart";
import { CgProfile } from "react-icons/cg";
const ProductDetails = ({ data }) => {
  const [Datanew,setDatanew] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const [openCart, setOpenCart] = useState(false);

  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);

  
  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/shop/get-shop-info/${data?.shop._id}`).then((res) => {
     setDatanew(res.data.shop);
     setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    })
  }, [data])

  const incrementCount = () => {
    if (data.stock <= count) {
      toast.error("Product stock limited!");
    }else {
    setCount(count + 1);
  }
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
     // Show the cart after adding item
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
      setOpenCart(true);
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
        setOpenCart(true);   
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      window.scrollTo({ top: 60, behavior: "smooth" });
    }
    else{
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [select]);

  return (
    <div className="bg-white">
      {/* Cart Component */}
      {openCart ? <Cart setOpenCart={setOpenCart} /> : null}
      {/* Rest of the component code */}
      {data ? (
        <div className={`${styles.section} w-[95%] min-[800px]:w-[95%] min-[1000px]:w-[85%] min-[1200px]:w-[80%]`}>
          <div className="w-full py-8">
            <div className="block w-full min-[800px]:flex">
              <div className="w-[100%] min-[800px]:w-[50%] min-[800px]:mr-6 pt-6">
              <div className="flex justify-center">
              <div className=" hover:max-h-[400px] hover:sm:max-h-[500px] w-[auto] hover:overflow-scroll">
                <img id="seletedImg" className="w-[auto] max-h-[400px] sm:max-h-[500px] hover:max-h-[190%] hover:max-w-[190%] rounded-xl"
                  src={`${data && data.images[select]?.url}`}
                  alt=""
                /></div></div>
                <div className="w-full flex justify-center pt-6">
                  {data &&
                    data.images.map((i, index) => (
                      <div
                        className={`${
                          select === 0 ? "border" : "null"
                        } cursor-pointer`}
                      >
                        <img
                          src={`${i?.url}`}
                          alt=""
                          className="w-auto max-h-[100px] overflow-scroll p-2"
                          onClick={() => setSelect(index)}
                        />
                      </div>
                    ))}
                  <div
                    className={`${
                      select === 1 ? "border" : "null"
                    } cursor-pointer`}
                  ></div>
                </div>
              </div>
              <div className="w-full min-[800px]:w-[50%] pt-5">
                <h1 className={`${styles.productTitle}`}>{data.name}</h1>
                <p className="text-[14px] 800px:text-[16px] leading-8 py-2 whitespace-pre-line">
              {data.description.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      <span className="font-[600] text-[18px]">↠ </span><span>{line}</span>
      <br />
    </React.Fragment>
  ))}
</p>
            <div className="flex">
            <p className="font-[500]">Quality:&nbsp;</p>
              {data.tags}
          </div>
                <div className="flex pt-5">
                  <h4 className={`${styles.productDiscountPrice}`}>
                  Rs. {data.discountPrice.toFixed(2)}
                  </h4>
                  <h3 className={`${styles.price}`}>
                    Rs. {data.originalPrice.toFixed(2) ? data.originalPrice.toFixed(2) : null}
                  </h3>
                </div>

                <div className="flex items-center mt-5 justify-between pr-3">
                  <div>
                    <button
                      className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">
                      {count}
                    </span>
                    <button
                      className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => removeFromWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => addToWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>
                <p className="text-[20px] pt-10 pb-2 font-[600] text-green-800">
                    In stock: {data.stock}
                  </p>
                <div
                  className={`${styles.button} !mt-0 !rounded !h-10 flex items-center`}
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="text-white flex items-center">
                    Add to cart <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
                <div className="min-[1000px]:flex items-center pt-3 pb-3 md:pt-5">
                  <div className="flex mt-4 mr-4">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className={`${styles.shop_name}`}>
                        {Datanew.name}
                      </h3>
                    </Link>
                    {/* <h5 className="pb-3 text-[15px]">
                      ({averageRating}/5) Ratings
                    </h5> */}
                  </div>
                  <div
                    className={`${styles.button} bg-[#6443d1] md:mt-4 !rounded !h-10`}
                    onClick={handleMessageSubmit}
                  >
                    <span className="text-white flex items-center p-2">
                      Send Message <AiOutlineMessage className="ml-1" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-[14px] 800px:text-[16px] font-[400] leading-8 py-2 whitespace-pre-line">Your product will be delivered within 2 days of receiving your order confirmation.</p>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-around border-b pt-10 pb-2">
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
          {active === 1 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(2)}
          >
            Product Reviews
          </h5>
          {active === 2 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
      </div>
      {active === 1 ? (
        <>
          <p className="py-2 text-[14px] sm:text-[16px] leading-8 pb-10 whitespace-pre-line">
              {data.description1.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      <span className="font-[600] text-[20px]">↠ </span><span>{line}</span>
      <br />
    </React.Fragment>
  ))}
</p>

        </>
      ) : null}

      {active === 2 ? (
        <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll">
          {data &&
            data.reviews.map((item, index) => (
              <div className="w-full flex my-2">
                <CgProfile size={30} color="#000000"
                />
                <div className="pl-2 ">
                  <div className="w-full flex items-center">
                    <h1 className="font-[500] mr-3">{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p>{item.comment}</p>
                </div>
              </div>
            ))}

          <div className="w-full flex justify-center">
            {data && data.reviews.length === 0 && (
              <h5>No Reviews have for this product!</h5>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetails;
