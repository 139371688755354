const styles = {
    custom_container: "w-11/12 hidden font-[sans-serif] sm:block",
    heading: 'text-[27px] text-left md:text-start font-[600] font-[sans-serif] pb-[20px]',
    section:'w-11/12 mx-auto font-[sans-serif]',
    productTitle: 'text-[16px] font-[sans-serif] sm:text-[20px] font-[600] font-Roboto text-[#333]',
    productDiscountPrice:"font-[600] text-[18px] font-[sans-serif] text-[#333] font-Roboto",
    price:"font-[600] text-[18px] text-[#d55b45] font-[sans-serif] pl-3 mt-[-4px] line-through",
    shop_name:" text-[15px] font-[sans-serif] text-green-800 font-[600]",
    active_indicator:"absolute bottom-[-27%] left-0 h-[3px] font-[sans-serif] w-full bg-[crimson]",
    button: 'w-[150px] bg-black h-[50px] my-1 flex font-[sans-serif] items-center justify-center rounded-xl cursor-pointer',
    cart_button: "px-[20px] h-[38px] rounded-[20px] bg-[#f63b60] font-[sans-serif] flex items-center justify-center cursor-pointer",
    cart_button_text:"text-[#fff] text-[16px] font-[sans-serif] font-[600]",
    input:"w-full border p-1 font-[sans-serif] rounded-[5px]",
    activeStatus:"w-[10px] h-[10px] font-[sans-serif] rounded-full absolute top-0 right-1 bg-[#40d132]",
    noramlFlex:"flex font-[sans-serif] items-center"
  };
  
  export default styles;
  