import {React, useEffect} from "react";
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";
import Whatsapp from "../components/Whatsapp/whatsapp";
import { categoriesData } from "../static/data";
import LanguageSelector from "../components/Language-selector";
const HomePage = () => {
  useEffect(() => {
    document.title = 'Shop - A Conscious Store';
  }, []);

  return (
    <div>
      <Header activeHeading={1} />
      {/* <LanguageSwitcher/> */}
      <Hero categoriesData={categoriesData} />
      <Categories />
      <br />
      <BestDeals />
      <br />
      <Events />
      <FeaturedProduct />
      <Sponsored />
      <Whatsapp />
      <Footer />
    </div>
  );
};

export default HomePage;
