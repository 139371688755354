import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cart";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import Cart from "../../cart/Cart";

const ProductDetailsCard = ({ setOpen, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  //   const [select, setSelect] = useState(false);

  const handleMessageSubmit = () => {};

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const incrementCount = () => {
    if (data.stock <= count) {
      toast.error("Product stock limited!");
    }else {
    setCount(count + 1);
  }
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
      setOpenCart(true);
    } else {
      if (data.stock < count) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
        setOpenCart(true);
      }
    }
  };

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return (
    <div className="bg-[#fff]">
      {openCart ? <Cart setOpenCart={setOpenCart} /> : null}
      {data ? (
        <div className="fixed w-full h-screen top-0 left-0 bg-[#00000030] z-30 p-5 flex items-center justify-center">
          <div className="w-[95%] min-[400px]:w-[90%] min-[600px]:w-[80%] min-[800px]:w-[95%] min-[1000px]:w-[80%] min-[1200px]:w-[70%] max-h-[90vh] overflow-y-scroll 800px:h-[85vh] bg-white rounded-md shadow-sm relative">
          <div className="w-full flex justify-end">
            <RxCross1
              size={30}
              className="cursor-pointer fixed z-20 bg-red-600 rounded-sm p-1 text-white"
              onClick={() => setOpen(false)}
            /></div>

            <div className="block w-full min-[800px]:flex justify-between p-5">
              <div className="w-full min-[800px]:w-[50%]">
              <div className="flex justify-center p-4" >
                <img className="w-[auto] max-h-[400px] sm:max-h-[500px] rounded-xl"
                 src={`${data.images && data.images[0]?.url}`} alt="" />
              </div>
              </div>
              <div className="w-full min-[800px]:w-[50%] pt-5">
                <h1 className={`${styles.productTitle} text-[20px]`}>
                  {data.name}
                </h1>
                                <p className="text-[14px] 800px:text-[16px] leading-8 py-2 whitespace-pre-line">
                              {data.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      <span className="font-[600] text-[18px]">↠ </span><span>{line}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </p>

                <div className="flex pt-3">
                  <h4 className={`${styles.productDiscountPrice}`}>
                  Rs. {data.discountPrice}
                  </h4>
                  <h3 className={`${styles.price}`}>
                  Rs. {data.originalPrice ? data.originalPrice : null}
                  </h3>
                </div>

                <div className="flex items-center mt-5 justify-between pr-3">
                  <div>
                    <button
                      className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">
                      {count}
                    </span>
                    <button
                      className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => removeFromWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => addToWishlistHandler(data)}
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${styles.button} mt-6 rounded-[4px] h-11 flex items-center`}
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="text-[#fff] flex items-center">
                    Add to cart <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailsCard;
