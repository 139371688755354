import {React, useEffect} from "react";
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import TrackOrder from "../components/Profile/TrackOrder";

const TrackOrderPage = () => {
  useEffect(() => {
    document.title = 'Track Orders - A Conscious Store';
  }, []);
  return (
    <div>
        <Header />
        <TrackOrder />
        <Footer />
    </div>
  )
}

export default TrackOrderPage