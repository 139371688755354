import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux"; // Import useDispatch
import { updateCartItemQuantity } from "../../redux/actions/cart";

const CartSingle = ({ data, removeFromCartHandler }) => {
  // Remove quantityChangeHandler since we're using updateCartItemQuantity

  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;
  const dispatch = useDispatch(); // Initialize useDispatch hook

  const handleQuantityChange = (updatedData) => {
    // Dispatch the action creator with the updated data
    dispatch(updateCartItemQuantity(updatedData));
  };

  const increment = () => {
    if (data.stock <= value) {
      toast.error("Product stock limited!");
    } else {
      const updatedValue = value + 1;
      setValue(updatedValue);
      const updateCartData = { ...data, qty: updatedValue };
      handleQuantityChange(updateCartData); // Call handleQuantityChange instead of quantityChangeHandler
    }
  };

  const decrement = () => {
    const updatedValue = value === 1 ? 1 : value - 1;
    setValue(updatedValue);
    const updateCartData = { ...data, qty: updatedValue };
    handleQuantityChange(updateCartData); // Call handleQuantityChange instead of quantityChangeHandler
  };

  return (
    <div className="border-b p-4">
      <div className="w-full flex justify-between items-center">
        <div className="w-full flex items-center">
        <div>
          <div
            className={`bg-[#e44343] border border-[#e4434373] rounded-full w-[25px] h-[25px] ${styles.noramlFlex} justify-center cursor-pointer`}
            onClick={increment}
          >
            <HiPlus size={18} color="#fff" />
          </div>
          <span className="pl-[10px]">{value}</span>
          <div
            className="bg-[#a7abb14f] rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
            onClick={decrement}
          >
            <HiOutlineMinus size={16} color="#7d879c" />
          </div>
        </div>
        <img
          src={`${data?.images[0]?.url}`}
          alt=""
          className="w-[auto] h-[50px] 400px:h-[80px] sm:h-[100px] ml-2 mr-2 rounded-[5px]"
        />
        <div className="pl-[5px]">
          <h1 className="text-[14px] sm:text-[16px] pr-1">{data.name}</h1>
          <h4 className="font-[400] text-[15px] text-[#00000082]">
            {data.discountPrice.toFixed(2)} * {value}
          </h4>
          <h4 className="font-[600] text-[17px] pt-[3px] text-[#d02222] font-Roboto">
            Rs. {totalPrice.toFixed(2)}
          </h4>
        </div>
        </div>
        <RxCross1
          size={20}
          className="cursor-pointer"
          onClick={() => removeFromCartHandler(data)}
        />
      </div>
    </div>
  );
};

export default CartSingle;
