import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const ChatBox = ({ phoneNumber }) => {
  const openWhatsAppChat = () => {
    if (phoneNumber) {
      window.open(`https://wa.me/${phoneNumber}`, "_blank");
    } else {
      console.error("WhatsApp phone number is not provided.");
    }
  };

  return (
    <div className="fixed bottom-2 z-10 left-4">
      <button
        onClick={openWhatsAppChat}
        className="bg-green-800 text-white flex items-center justify-center rounded-full p-3 shadow-md hover:bg-green-600 focus:outline-none"
        style={{ minWidth: "100px" }}
      >
        <FaWhatsapp className="mr-2 text-xl sm:text-2xl" />{" "}
        {/* Adjust size based on screen */}
        <span className="hidden font-[600] sm:inline">Chat on WhatsApp</span>{" "}
        {/* Hide on smaller screens */}
      </button>
    </div>
  );
};

const App = () => {
  const phoneNumber = "+971547584609"; // Your WhatsApp phone number
  return (
    <div>
      <ChatBox phoneNumber={phoneNumber} />
    </div>
  );
};

export default App;
