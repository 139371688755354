import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, useParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import Whatsapp from "../components/Whatsapp/whatsapp";

import styles from "../styles/styles";

const ProductsPage = () => {

  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const subcategoryData = searchParams.get("subcategory");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { category, subcategory } = useParams();
  useEffect(() => {
    console.log("Category:", category);
    console.log("Subcategory:", subcategory);
  }, [category, subcategory]);

  useEffect(() => {
    let title = 'All Products - A Conscious Store';
  
    if (!categoryData && !subcategoryData) {
      setFilteredProducts(allProducts);
    } else {
      const filtered =
        allProducts &&
        allProducts.filter((product) => {
          if (subcategoryData) {
            // Filter products by both category and subcategory
            return (
              product.category === categoryData &&
              product.subcategory === subcategoryData
            );
          } else if (categoryData) {
            // Filter products by category only
            return product.category === categoryData;
          }
          return false;
        });
  
      setFilteredProducts(filtered);
  
      // Update title based on the filtering
      if (categoryData && subcategoryData) {
        title = `${subcategoryData} - ${categoryData} - A Conscious Store`;
      } else if (categoryData) {
        title = `${categoryData} - A Conscious Store`;
      }
    }
  
    // Set the document title
    document.title = title;
  }, [allProducts, categoryData, subcategoryData]);
  



  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <br />
          <br />
          <div className={`${styles.section}`}>
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {filteredProducts &&
                filteredProducts.map((product, index) => (
                  <ProductCard data={product} key={index} />
                ))}
            </div>
            {filteredProducts && filteredProducts.length === 0 ? (
              <h1 className="text-center w-full pb-[100px] text-[20px]">
                No products found !
              </h1>
            ) : null}
          </div>
          <Whatsapp/>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
