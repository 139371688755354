import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import CountDown from "./CountDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Cart from "../cart/Cart";


const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [openCart, setOpenCart] = useState(false);

  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
      setOpenCart(true);
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
        setOpenCart(true);
      }
    }
  };

  const closeCart = () => {
    setOpenCart(false);
  };

  const toggleCart = () => {
    setOpenCart(!openCart);
  };

  return (
<>
<Link to={`/product/${data._id}`}>
    <div
      className={`w-full block mt-8 bg-white shadow-xl justify-center rounded-lg ${
        active ? "unset" : "mb-12"
      } lg:flex p-2`}
    >
      
     
      <div className="w-full lg:-w[50%] justify-center flex items-center p-2 m-auto">
        <img class="w-auto max-h-60 rounded-md" src={`${data.images[0]?.url}`} alt="" />
      </div>
      <div className="w-full lg:[w-50%] flex flex-col p-2 justify-center">
        <h2 className="text-[16px] font-[600] text-center lg:text-left font-medium]">{data.name}</h2>
        <div className="py-2">
          <div className="flex justify-center lg:justify-start">
            <h5 className="font-[700] text-[20px] text-[#d55b45] pr-3 line-through">
              Rs. {data.originalPrice.toFixed(2)}
            </h5>
            <h5 className="font-[700] text-[20px] text-black font-Roboto">
              Rs. {data.discountPrice.toFixed(2)}
            </h5>
          </div>
        </div>
        <div className="flex justify-center">
        <CountDown data={data} /> </div>
        <br />
        <div className="flex justify-center"> 
        <div className="400px:flex items-center justify-center">
          <Link to={`/product/${data._id}`}>
            <div className={`${styles.button} text-[#fff] mb-5 text-[18px]`}>See Details</div>
          </Link>
          <div
            className={`${styles.button} text-[#fff] 400px:ml-5 mb-5 text-[18px]`}
            onClick={() => addToCartHandler(data)}
          >
            Add to cart
          </div>
        </div>
        </div>
      </div>
      
      
    </div>
    {/* Cart Section */}
    {openCart ? <Cart setOpenCart={setOpenCart} /> : null}
    </Link>
</>
  );
};

export default EventCard;
