import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import logo from "../../Assests/aarya.png";
// import cn from "../../Assests/cn.png";
import { categoriesData, productData } from "../../static/data";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";

const Header = ({ activeHeading }) => {
  const navigate = useNavigate(); // Initialize navigate
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim() === "") {
      setSearchData(null);
    } else {
      const formattedTerm = term.toLowerCase().replace(/\s+/g, ""); // Remove spaces from search term
      const filteredProducts =
        allProducts &&
        allProducts.filter((product) =>
          product.name.toLowerCase().replace(/\s+/g, "").includes(formattedTerm)
        );
      setSearchData(filteredProducts);
    }
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

const handleSearchIconClick = () => {
  setOpen(false)
    // Navigate to search page with search term
    navigate(`/search?term=${searchTerm}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setOpen(false)
      // Trigger search action when Enter key is pressed
      navigate(`/search?term=${searchTerm}`);
    }
  };

  return (
    <>
      <div className={`${styles.section}`}>
        <div className="hidden 800px:h-[20px] 800px:my-[20px] 800px:flex items-center justify-between">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/">
              <img className="pr-2 py-2 W-full h-[60px]"
                src={logo}
                alt=""
              />
            </Link>
            <Link to="/">
              <span className="text-green-800"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "600", // Make font bold
                }}
              >
             A Conscious Store
              </span>
            </Link>
          </div>

          {/* search box*/}
          <div className="w-[40%] relative">
            <input
              type="text"
              placeholder="Search Product..."
              value={searchTerm}
              onKeyPress={handleKeyPress} // Handle Enter key press
              onChange={handleSearchChange}
              className="h-[40px] w-full px-2 border-black border-[2px] rounded-md"
            />
            <AiOutlineSearch
              size={30}
              className="absolute right-2 top-1 cursor-pointer"
              onClick={handleSearchIconClick} // Handle click on search icon
            />
          </div>
        </div>
      </div>

      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-20" : null
        } transition hidden 800px:flex items-center w-full bg-green-200 h-[60px]`}
      >
        <div
          className={`${styles.section} relative ${styles.noramlFlex} justify-between`}
        >
          {/* categories */}
          <div onClick={() => setDropDown(!dropDown)}>
            <div className="hidden 800px:block 800px:h-[60px]">
              <BiMenuAltLeft
                size={30}
                className="absolute cursor-pointer 800px:h-[60px] z-20"
              />
              <button style={{fontFamily: 'Arial, Helvetica, sans-serif'}}
                className={`hidden 1000px:flex 1000px:h-[60px] justify-between items-center pl-10 font-sans text-lg font-[600] select-none rounded-t-md`}
              >
                All Categories
              </button>
              {dropDown ? (
                <div className="800px:absolute 800px:bg-white mt-[60px] 1000px:mt-[0px] z-20 h-[500px] overflow-y-scroll">
                <DropDown
                  categoriesData={categoriesData}
                  setDropDown={setDropDown}
                  setOpen={setOpen}
                />
                 </div>
              ) : null}
            </div>
          </div>
          {/* navitems */}
          <div className={`${styles.noramlFlex}`}>
            <Navbar active={activeHeading}
            setOpen={setOpen} />
          </div>

          <div className="flex">
            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenWishlist(true)}
              >
                <AiOutlineHeart size={30} color="#000000" />
                <span className="absolute right-0 top-0 rounded-full bg-green-800 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenCart(true)}
              >
                <AiOutlineShoppingCart size={30} color="#000000" />
                <span className="absolute right-0 top-0 rounded-full bg-green-800 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {cart && cart.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer">
                {isAuthenticated ? (
                  <Link to="/profile">
                    <div className="flex flex-col bg-gray-100 rounded-xl p-2">
                    <h3 className="text-extrabold text-center text-black font-[800] text-[14px]">MY</h3>
                   <h3 className="text-extrabold text-center text-black font-[800] text-[14px]">PROFILE</h3>
                   </div>
                  </Link>
                ) : (
                  <Link to="/login">
                    <CgProfile size={30} color="#000000" />
                  </Link>
                )}
              </div>
            </div>

            {/* cart popup */}
            {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

            {/* wishlist popup */}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-20" : null
        }
      w-full h-[80px] flex justify-center bg-[#fff] z-20 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div className="w-full flex items-center justify-between">
          <div>
            <BiMenuAltLeft
              size={40}
              className="ml-4"
              onClick={() => setOpen(true)}
            />     
          </div>
          <div>
          <div className="flex flex-col items-center justify-center">         
          <div className="">
            <Link to="/">
              <img className=""
                src={logo}
                alt=""
                style={{ width: "auto", height: "40px" }} // Adjust width and height as needed
              />
            </Link>
          </div>
          <div className="pt-1">
                  {isAuthenticated ? (
                    <div>
                      <Link to="/profile">
                    <div>
                    <h3 className="text-[16px] text-center font-[500] text-[#000000b7]">My Profile</h3>
                   </div>
                  </Link>
                    </div>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        className="text-[16px] text-center font-[500] text-[#000000b7]"
                      >
                        Login /
                      </Link>
                      <Link
                        to="/sign-up"
                        className="text-[16px] text-center font-[500] text-[#000000b7]"
                      >
                        {" "}
                         Sign-Up
                      </Link>
                    </>
                  )}
                </div>
                </div>
                </div>
          <div>
            <div
              className="relative mr-[20px]"
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} />
              <span class="absolute right-0 top-0 rounded-full bg-green-800 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                {cart && cart.length}
              </span>
            </div>
          </div>
          {/* cart popup */}
          {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

          {/* wishlist popup */}
          {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
        </div>

        {/* header sidebar */}
        {open && (
          <div className="fixed w-full h-full top-0 left-0 z-20 bg-[#0000005f]">
            <div className="fixed w-[80%] min-[600px]:w-[50%] min-[400px]:w-[70%] h-screen top-0 left-0 z-30 bg-[#fff] overflow-y-scroll">
              <div className="relative">
                <div className="w-full justify-between flex pr-3">
                  <div>
                    <div
                      className="relative mr-[15px]"
                      onClick={() => setOpenWishlist(true) || setOpen(false)}
                    >
                      <AiOutlineHeart
                        size={30}
                        color="#000000"
                        className="mt-5 ml-3"
                      />
                      <span className="absolute right-0 top-0 rounded-full bg-green-800 w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                        {wishlist && wishlist.length}
                      </span>
                    </div>
                  </div>
                  <RxCross1
                    size={30}
                    className="ml-4 mt-5"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div className="mt-8 w-[92%] m-auto h-[40px relative]">
                  <input
                    type="search"
                    placeholder="Search Product..."
                    className="h-[40px] w-full pl-2 pr-12 border-black border-[2px] rounded-md"
                    value={searchTerm}
                    onKeyPress={handleKeyPress} // Handle Enter key press
                    onChange={handleSearchChange}
                  />
                   <AiOutlineSearch
              size={30}
              className="absolute right-6 top-[88px] cursor-pointer"
              onClick={handleSearchIconClick} // Handle click on search icon
            />
                </div>
                {/* Dropdown */}
                {/* Dropdown */}
                {/* Dropdown */}
                <div className="relative">
                  {/* Dropdown */}
                  <div onClick={() => setDropDown(!dropDown)}>
                    <div className="relative h-[60px] mt-[10px] w-full">
                      <button style={{fontFamily: 'Arial, Helvetica, sans-serif'}}
                        className={`h-[100%] w-full flex justify-between items-center pl-6 bg-white-200 font-sans text-lg font-[600] select-none rounded-t-md`}
                      >
                        All Categories
                      </button>

                      <IoIosArrowDown
                        size={20}
                        className="absolute right-2 top-5 cursor-pointer"
                        onClick={() => setDropDown(!dropDown)}
                      />
                      {/* Adjust visibility and width based on dropDown state */}
                      {dropDown ? (
                        <div className="absolute top-full left-0 w-full bg-white z-20">
                          <DropDown
                            categoriesData={categoriesData}
                            setDropDown={setDropDown}
                            setOpen={setOpen}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Navbar */}
                <div className="relative z-10 mt-3">
                  <Navbar active={activeHeading} 
                  setOpen={setOpen}
                  />
                </div>
                <div className="flex w-full justify-start ">
                  {isAuthenticated ? (
                    <div className="ml-4">
                      <Link to="/profile">
                    <div className="flex flex-col bg-gray-300 rounded-xl p-2">
                    <h3 className="text-extrabold text-center text-black font-[800] text-[14px]">MY</h3>
                   <h3 className="text-extrabold text-center text-black font-[800] text-[14px]">PROFILE</h3>
                   </div>
                  </Link>
                    </div>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        className="text-[18px] font-[500] ml-4 pb-2 pr-[10px] text-[#000000b7]"
                      >
                        Login  /
                      </Link>
                      <Link
                        to="/sign-up"
                        className="text-[18px] font-[500] pb-2 text-[#000000b7]"
                      >
                        Sign up
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
