// navigation Data
export const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Best Selling",
    url: "/best-selling",
  },
  {
    title: "Products",
    url: "/products",
  },
  {
    title: "Events",
    url: "/events",
  },
  {
    title: "FAQ",
    url: "/faq",
  },
];

// categories data
export const categoriesData = [
  {
    id: 1,
    title: "Laptops",
    subTitle: "",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/NEWAppleMacbookProwithM1ProChip14InchLaptop2021ModelMKGQ3LL_A_16GB_1TBSSD_custommacbd.jpg?v=1659592838",
    subcategories: [
      {
        id: 11,
        title: "New Laptops",
        image_Url:
          "https://img.freepik.com/free-vector/laptop_53876-43923.jpg?w=826&t=st=1711711408~exp=1711712008~hmac=d0a449a4f40f5505df7878ddb68d3eca5843baae58ae271f0a0c936ffe2f5c82",
      },
      {
        id: 12,
        title: "Used Laptops",
        image_Url:
          "https://img.freepik.com/free-photo/elevated-view-laptop-stationeries-blue-backdrop_23-2147880457.jpg?t=st=1711711845~exp=1711715445~hmac=d54c4176ad4073d1f53fb398a0276f54a967045501c572fee18538d84889385d&w=1380",
      },
      // Add more subcategories as needed
    ],
  },

  {
    id: 8,
    title: "Computers",
    subTitle: "",
    image_Url:
      "https://cdn.shopify.com/s/files/1/1706/9177/products/NEWAppleMacbookProwithM1ProChip14InchLaptop2021ModelMKGQ3LL_A_16GB_1TBSSD_custommacbd.jpg?v=1659592838",
    subcategories: [
      {
        id: 47,
        title: "New Computers",
        image_Url:
          "https://img.freepik.com/free-vector/laptop_53876-43923.jpg?w=826&t=st=1711711408~exp=1711712008~hmac=d0a449a4f40f5505df7878ddb68d3eca5843baae58ae271f0a0c936ffe2f5c82",
      },
      {
        id: 48,
        title: "Used Computers",
        image_Url:
          "https://img.freepik.com/free-photo/elevated-view-laptop-stationeries-blue-backdrop_23-2147880457.jpg?t=st=1711711845~exp=1711715445~hmac=d54c4176ad4073d1f53fb398a0276f54a967045501c572fee18538d84889385d&w=1380",
      },
      // Add more subcategories as needed
    ],
  },

  {
    id: 2,
    title: "Smart Phones",
    subTitle: "",
    image_Url:
      "https://img.freepik.com/free-vector/realistic-display-smartphone-with-different-apps_52683-30241.jpg?t=st=1711711889~exp=1711715489~hmac=fdfab92ea4bae95a70d70b9e3a207f814bedbb3bae1029439e9e165f7508f283&w=826",
    subcategories: [
      {
        id: 21,
        title: "New Smartphones",
        image_Url:
          "https://img.freepik.com/free-photo/electronic-device-balancing-concept_23-2150422324.jpg?t=st=1711712003~exp=1711715603~hmac=af9c0fc5433f39dc1b55bc624c0c530746a158191daa92079ab257244d2c71bb&w=740",
      },
      {
        id: 22,
        title: "Used Smartphones",
        image_Url:
          "https://img.freepik.com/free-photo/asian-man-hands-using-smart-phone-social-media-concept_1384-191.jpg?t=st=1711712026~exp=1711715626~hmac=b8ed853840a92207237f485a29050609d580543f9d76326fd4181733b0c10187&w=1380",
      },
      // Add more subcategories as needed
    ],
  },
  {
    id: 3,
    title: "Accessories",
    subTitle: "",
    image_Url:
      "https://img.freepik.com/free-vector/work-elements-collection_1297-136.jpg?t=st=1711712082~exp=1711715682~hmac=cbe9208f6de63b957cd8b2b0fcb67fb1319e2b9123c963870fc35cc31419e923&w=1380",
    subcategories: [
      {
        id: 21,
        title: "Smartphone Accessories",
        image_Url:
          "https://img.freepik.com/free-vector/wearable-technology-isometric-composition-swatch_1284-25895.jpg?t=st=1711712113~exp=1711715713~hmac=72b5d6a8790f8034d647fbc4592a59c58541ae1a6432df46d59d69d4ad17e47e&w=826",
      },
      {
        id: 22,
        title: "Laptop Accessories",
        image_Url:
          "https://img.freepik.com/premium-photo/high-angle-view-technology-with-eyeglasses-table_1048944-13479125.jpg?w=1380",
      },
      {
        id: 49,
        title: "Computer Accessories",
        image_Url:
          "https://img.freepik.com/premium-photo/high-angle-view-technology-with-eyeglasses-table_1048944-13479125.jpg?w=1380",
      },
      // Add more subcategories as needed
    ],
  },
  {
    id: 4,
    title: "Home Appliances",
    subTitle: "",
    image_Url:
      "https://img.freepik.com/free-vector/realistic-household-kitchen-appliances-set_1284-26013.jpg?t=st=1711712171~exp=1711715771~hmac=69a8b22241aafd98d620a964709cbb0ba985920d48c9bfd4eeadf692154437f8&w=740",
    subcategories: [
      {
        id: 31,
        title: "New Appliances",
        image_Url:
          "https://img.freepik.com/free-vector/realistic-household-kitchen-appliances-set_1284-26013.jpg?t=st=1711712171~exp=1711715771~hmac=69a8b22241aafd98d620a964709cbb0ba985920d48c9bfd4eeadf692154437f8&w=740",
      },
      {
        id: 32,
        title: "Used Appliances",
        image_Url:
          "https://img.freepik.com/free-photo/view-laundry-room-with-washing-machine-retro-colors_23-2151176179.jpg?t=st=1711712231~exp=1711715831~hmac=b9dbddae34af13e6f0b6d3232d83ad2b46ca8a3b3698745fdef1253a69c3ab80&w=1380",
      },
      // Add more subcategories as needed
    ],
  },

  {
    id: 5,
    title: "Electronics Items",
    subTitle: "",
    image_Url:
      "https://img.freepik.com/free-vector/wearable-technology-isometric-flowchart_1284-19018.jpg?t=st=1711712249~exp=1711715849~hmac=0675ed987a250cb89d503394698ae3c0466924d78371905859490564a75f23ea&w=996",
    subcategories: [
      {
        id: 41,
        title: "New Electronic Item",
        image_Url:
          "https://img.freepik.com/free-photo/travel-still-life-pack-flat-lay_23-2148837347.jpg?t=st=1711712270~exp=1711715870~hmac=8bdbc73dc3841ef951562968aa400dc3c161007189299ea4c33a33d9040e68cb&w=826",
      },
      {
        id: 42,
        title: "Used Electronic Item",
        image_Url:
          "https://img.freepik.com/free-photo/still-life-teenager-s-desk_23-2149371274.jpg?t=st=1711712311~exp=1711715911~hmac=434721a81518697cb02cd798a72149ef67055f08e3a6eda205c10996aebd24fc&w=1380",
      },
      // Add more subcategories as needed
    ],
  },
  {
    id: 6,
    title: "Books",
    subTitle: "",
    image_Url:
      "https://img.freepik.com/free-vector/wearable-technology-isometric-flowchart_1284-19018.jpg?t=st=1711712249~exp=1711715849~hmac=0675ed987a250cb89d503394698ae3c0466924d78371905859490564a75f23ea&w=996",
    subcategories: [
      {
        id: 43,
        title: "New Books",
        image_Url:
          "https://img.freepik.com/free-photo/travel-still-life-pack-flat-lay_23-2148837347.jpg?t=st=1711712270~exp=1711715870~hmac=8bdbc73dc3841ef951562968aa400dc3c161007189299ea4c33a33d9040e68cb&w=826",
      },
      {
        id: 44,
        title: "Used Books",
        image_Url:
          "https://img.freepik.com/free-photo/still-life-teenager-s-desk_23-2149371274.jpg?t=st=1711712311~exp=1711715911~hmac=434721a81518697cb02cd798a72149ef67055f08e3a6eda205c10996aebd24fc&w=1380",
      },
      // Add more subcategories as needed
    ],
  },
  {
    id: 7,
    title: "Clothes",
    subTitle: "",
    image_Url:
      "https://img.freepik.com/free-vector/wearable-technology-isometric-flowchart_1284-19018.jpg?t=st=1711712249~exp=1711715849~hmac=0675ed987a250cb89d503394698ae3c0466924d78371905859490564a75f23ea&w=996",
    subcategories: [
      {
        id: 45,
        title: "Men Clothes",
        image_Url:
          "https://img.freepik.com/free-photo/travel-still-life-pack-flat-lay_23-2148837347.jpg?t=st=1711712270~exp=1711715870~hmac=8bdbc73dc3841ef951562968aa400dc3c161007189299ea4c33a33d9040e68cb&w=826",
      },
      {
        id: 46,
        title: "Women Clothes",
        image_Url:
          "https://img.freepik.com/free-photo/still-life-teenager-s-desk_23-2149371274.jpg?t=st=1711712311~exp=1711715911~hmac=434721a81518697cb02cd798a72149ef67055f08e3a6eda205c10996aebd24fc&w=1380",
      },
      // Add more subcategories as needed
    ],
  },
  // {
  //   id: 5,
  //   title: "Accessories",
  //   subTitle: "",
  //   image_Url:
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvBQPQMVNRd6TtDkGs2dCri0Y-rxKkFOiEWw&usqp=CAU",
  // },
  // {
  //   id: 6,
  //   title: "Laptop Accessories",
  //   subTitle: "",
  //   image_Url:
  //     "https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg",
  // },
  // {
  //   id: 7,
  //   title: "Smartphone Accessories",
  //   subTitle: "",
  //   image_Url: "https://cdn.openpr.com/T/c/Tc15444071_g.jpg",
  // },
  // {
  //   id: 8,
  //   title: "Electronic Item",
  //   subTitle: "",
  //   image_Url:
  //     "https://st-troy.mncdn.com/mnresize/1500/1500/Content/media/ProductImg/original/mpwp3tua-apple-iphone-14-256gb-mavi-mpwp3tua-637986832343472449.jpg",
  // },
  // {
  //   id: 9,
  //   title: "Music and Gaming",
  //   subTitle: "",
  //   image_Url:
  //     "https://static.vecteezy.com/system/resources/previews/011/996/555/original/3d-black-headphone-illustration-ecommerce-icon-png.png",
  // },
  // {
  //   id: 10,
  //   title: "Others",
  //   subTitle: "",
  //   image_Url:
  //     "https://searchspring.com/wp-content/uploads/2022/10/Hero-Image-Platform-Others-2.png",
  // },
];

// branding data
export const brandingData = [
  {
    id: 1,
    title: "Free Shipping",
    Description: "All Over NEPAL",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1H5.63636V24.1818H35"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M8.72763 35.0002C10.4347 35.0002 11.8185 33.6163 11.8185 31.9093C11.8185 30.2022 10.4347 28.8184 8.72763 28.8184C7.02057 28.8184 5.63672 30.2022 5.63672 31.9093C5.63672 33.6163 7.02057 35.0002 8.72763 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31.9073 35.0002C33.6144 35.0002 34.9982 33.6163 34.9982 31.9093C34.9982 30.2022 33.6144 28.8184 31.9073 28.8184C30.2003 28.8184 28.8164 30.2022 28.8164 31.9093C28.8164 33.6163 30.2003 35.0002 31.9073 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M34.9982 1H11.8164V18H34.9982V1Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M11.8164 7.18164H34.9982"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Daily Surprise Offers",
    Description: "Save up to 25% off",
    icon: (
      <svg
        width="32"
        height="34"
        viewBox="0 0 32 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 17.4502C31 25.7002 24.25 32.4502 16 32.4502C7.75 32.4502 1 25.7002 1 17.4502C1 9.2002 7.75 2.4502 16 2.4502C21.85 2.4502 26.95 5.7502 29.35 10.7002"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M30.7 2L29.5 10.85L20.5 9.65"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 4,
    title: "Affortable Prices",
    Description: "Get Factory direct price",
    icon: (
      <svg
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13H5.5C2.95 13 1 11.05 1 8.5V1H7"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M25 13H26.5C29.05 13 31 11.05 31 8.5V1H25"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 28V22"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 22C11.05 22 7 17.95 7 13V1H25V13C25 17.95 20.95 22 16 22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M25 34H7C7 30.7 9.7 28 13 28H19C22.3 28 25 30.7 25 34Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 5,
    title: "Secure Payments",
    Description: "100% protected payments",
    icon: (
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6654 18.667H9.33203V27.0003H22.6654V18.667Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M12.668 18.6663V13.6663C12.668 11.833 14.168 10.333 16.0013 10.333C17.8346 10.333 19.3346 11.833 19.3346 13.6663V18.6663"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31 22C31 30.3333 24.3333 37 16 37C7.66667 37 1 30.3333 1 22V5.33333L16 2L31 5.33333V22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
];
export const footerProductLinks = [
  {
    name: "About us",
    link: "/about",
  },
  {
    name: "Careers",
    link: "/carrers",
  },
  {
    name: "Store Locations",
  },
  {
    name: "Our Blog",
  },
  {
    name: "Reviews",
  },
];

export const footercompanyLinks = [
  {
    name: "Game & Video",
  },
  {
    name: "Phone &Tablets",
  },
  {
    name: "Computers & Laptop",
  },
  {
    name: "Sport Watches",
  },
  {
    name: "Events",
  },
];

export const footerSupportLinks = [
  {
    name: "FAQ",
    link: "faq",
  },
  {
    name: "Reviews",
  },
  {
    name: "Contact Us",
  },
  {
    name: "Shipping",
  },
  {
    name: "Live chat",
  },
];
